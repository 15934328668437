<!-- home -->
<template>
    <div class="page-box index-container">
        <h1 class="demo-home__title"><span> Vant h5页面模板</span></h1>
        <p class="tips">Vant 轻量、可靠的移动端 Vue 组件库</p>
        <h2 class="label">特性</h2>
        <van-cell icon="success" v-for="item in list" :key="item" :title="item" />
        <p class="api">vant 官网api</p>
        <a class="link" href="https://vant-contrib.gitee.io/vant/#/zh-CN/home">https://vant-contrib.gitee.io/vant/#/zh-CN/home</a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                '60+ 高质量组件',
                '90% 单元测试覆盖率',
                '完善的中英文文档和示例',
                '支持按需引入',
                '支持主题定制',
                '支持国际化',
                '支持 TS',
                '支持 SSR'
            ]
        }
    },

    computed: {},

    mounted() { },

    methods: {}
}
</script>
<style lang="less" scoped>
.index-container {
    padding: 12px;
    background: #fff;

    .demo-home__title {
        margin: 0 0 6px;
        font-size: 32px;

        .demo-home__title img,
        .demo-home__title span {
            display: inline-block;
            vertical-align: middle;
        }

        img {
            position: relative;
            top: 6px;
            width: 32px;
        }

        span {
            margin-left: 16px;
            font-weight: 500;
        }
    }

    .demo-home__desc {
        margin: 0 0 20px;
        color: rgba(69, 90, 100, 0.6);
        font-size: 14px;
    }

    .tips {
        margin-top: 20px;
        text-align: center;
        font-size: 14px;
    }

    .label {
        margin-top: 20px;
        color: #666;
        font-size: 16px;
    }

    .api {
        margin: 20px 10px 10px;
        font-size: 14px;
    }

    .link {
        margin-left: 10px;
    }
}</style>
